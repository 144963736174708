import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
// ... Si besoin ajouter d'autres librairies

library.add(fab, fas, far)
Vue.component('fa', FontAwesomeIcon)
