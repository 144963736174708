export default {
  today: "Aujourd'hui",
  other: 'autre',
  price: '{price}€',
  free: 'Gratuit',
  Event: {
    tierName: 'place | places'
  },
  Membership: {
    tierName: 'place | places'
  },
  Shop: {
    tierName: 'produit | produits'
  },
  CrowdFunding: {
    tierName: 'contrepartie | contreparties'
  },
  Donation: {
    tierName: 'don | dons'
  },
  PaymentForm: {
    tierName: 'paiement | paiements'
  },
  Checkout: {
    tierName: 'paiement | paiements'
  }
}
