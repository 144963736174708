const trackingFormType = {
  evenements: 'Billetterie',
  adhesions: 'Adhesion',
  formulaires: 'Don',
  collectes: 'Crowdfunding',
  paiements: 'Vente',
  boutiques: 'Boutique'
}

/**
 * Get tracking data depending on the application's route
 * @param {Object} route
 * @return {Object}
 */
const getTrackingRouteData = route => {
  const { params, path } = route
  const {
    organizationSlug: organization_slug = '',
    formType: form_type = '',
    campaignSlug: form_slug = '',
    steps = ''
  } = params
  const data = {
    rubrik: 'Parcours de commande',
    context: 'Contributeur',
    category: '',
    title: ''
  }
  const formattedFormType = trackingFormType[form_type || '']
  if (organization_slug) data.organization_slug = organization_slug
  if (form_type) data.form_type = form_type
  if (form_slug) data.form_slug = form_slug

  /**
   * Source: https://docs.google.com/spreadsheets/d/194FHx6xdPvaIBIVwukljO2nw_mx-8bIGkRgoGq-mfKI/edit#gid=1948717279
   */
  switch (true) {
    case /(.*)\/evenements\/(.*)\/widget/.test(path):
    case /(.*)\/boutiques\/(.*)\/widget/.test(path):
    case /(.*)\/adhesions\/(.*)\/widget/.test(path):
    case /(.*)\/collectes\/(.*)\/widget/.test(path):
      return {
        ...data,
        category: formattedFormType,
        title: `Widget parcours étape ${steps || 1} sur 4 - ${formattedFormType}`
      }
    case /(.*)\/evenements\/(.*)/.test(path):
    case /(.*)\/boutiques\/(.*)/.test(path):
    case /(.*)\/adhesions\/(.*)/.test(path):
    case /(.*)\/collectes\/(.*)/.test(path):
      return {
        ...data,
        category: formattedFormType,
        title: `Parcours étape ${steps || 1} sur 4 - ${formattedFormType}`
      }
    case /(.*)\/formulaires\/(.*)\/widget/.test(path):
    case /(.*)\/paiements\/(.*)\/widget/.test(path):
      return {
        ...data,
        category: formattedFormType,
        title: `Widget parcours ${formattedFormType}`
      }
    case /(.*)\/formulaires/.test(path):
    case /(.*)\/paiements/.test(path):
      return {
        ...data,
        category: formattedFormType,
        title: `Parcours ${formattedFormType}`
      }
    case /(.*)\/checkout/.test(path):
      return {
        ...data,
        category: 'Checkout',
        title: `Parcours Checkout`
      }
    case /(.*)\/paiement\/regularisation/.test(path):
      return {
        ...data,
        category: 'Régularisation paiement',
        title: `Parcours Régularisation`
      }
    default:
      return data
  }
}

export default getTrackingRouteData
