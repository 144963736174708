import { Plugin } from '@nuxt/types'
import Cookies from 'universal-cookie'
import { accessTokenCookieName, refreshTokenCookieName, sessionTokenCookieName } from '~/constants'

let cookies: Cookies | null = null

const cookiesPlugin: Plugin = ({
  $config: {
    NUXT_ENV_BASE_URL
  }
}, inject) => {
  const cookieOption = {
    path: '/',
    domain: NUXT_ENV_BASE_URL.replace(/https?:\/\/(www|local)/g, '')
  }

  cookies = cookies || new Cookies(cookies, cookieOption)
  
  const cookiesSnapshot = cookies.getAll()
  
  for (const cookieName in cookiesSnapshot) {
    if (Object.hasOwn(cookiesSnapshot, cookieName)) {
      const cookieValue = cookiesSnapshot[cookieName];
      if ((cookieValue === 'undefined' || cookieValue === '')) {
        cookies.remove(cookieName, cookieOption)
      }
    }
  }

  // force dirty clean
  if (process.client) {
    document.cookie.split(";").forEach(function (cookie) {
      const cookieName = cookie.split('=')[0].trim()
      const cookieValue = cookie.split('=')[1]
      if (
        [accessTokenCookieName, refreshTokenCookieName, sessionTokenCookieName].includes(cookieName)
        && (cookieValue === 'undefined' || cookieValue === '')) {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date(0).toUTCString() + ";path=/");
      }
    });
  }

  inject('cookies', cookies)
}

export default cookiesPlugin