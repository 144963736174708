/**
 * ### Node Env Helper
 * List of boolean to check current env
 */

const nodeEnv = {

  /**
   * ### Is Production
   * Is `true` if app build is started with `yarn start`.
   * 
   * In this case `NODE_ENV` is set with value `'production'`
   */
  isProduction: process.env.NODE_ENV === 'production',
  
  /**
   * ### Is Development
   * Is `true` if app build is started with `yarn serve`.
   * 
   * In this case `NODE_ENV` is set with value `'development'`
   */
  isDevelopment: process.env.NODE_ENV === 'development'
}

export default nodeEnv
