import { computed, inject, type Ref} from 'vue'
import { enum as enums } from '@ha/helpers'
import { useSsrState } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import useRouteParams from '@/composables/useRouteParams'
import { useContext } from '@nuxtjs/composition-api'

const formState: Ref<{
  isDescriptionCollapsed: boolean
  isLongDescription: boolean
}> = useSsrState('formState', () => ({
  isDescriptionCollapsed: true,
  isLongDescription: false,
}))

export default () => {
  const { form, compliance} = useStoreData()
  const { formType } = useRouteParams()
  const { $config } = useContext()

  const isCrowdfunding = computed(() => form.value?.formType === enums.FormType.CROWDFUNDING)
  const isEvent = computed(() => form.value?.formType === enums.FormType.EVENT)
  const isMembership = computed(() => form.value?.formType === enums.FormType.MEMBERSHIP)
  const isShop = computed(() => form.value?.formType === enums.FormType.SHOP)
  const isSale = computed(() => form.value?.formType === enums.FormType.PAYMENTFORM)
  const isDonation = computed(() => form.value?.formType === enums.FormType.DONATION)
  // there is no form - and so no form.formType - for checkout bc no campaignSlug, however there is a formType from route params
  const isCheckout = computed(
    () => !form.value?.formType && formType.value === enums.FormType.CHECKOUT
  )

  const isWidget = inject('isWidget')

  const isQuickWinForm = computed(
    () =>
      !isWidget &&
      $config.QUICK_WINS_FORM_ENABLED &&
      (isShop.value || isMembership.value || isEvent.value)
  )

  const isQuickWinNavEnabled = computed(
    () => isQuickWinForm.value && $config.QUICK_WINS_NAV_ENABLED
  )

  const isFeedbackFormEnabledEnv = computed(
    () => $config.CV_FEEDBACK_FORM 
  )

  const isFeedbackFormEnabled = computed(
    () => isFeedbackFormEnabledEnv.value && !isCheckout.value && compliance.value.paymentProviderType !== "Lemonway"
  )

  return {
    isCrowdfunding,
    isEvent,
    isMembership,
    isShop,
    isSale,
    isDonation,
    isCheckout,
    isQuickWinForm,
    isQuickWinNavEnabled,
    isFeedbackFormEnabledEnv,
    isFeedbackFormEnabled,
    formState,
  }
}
