import { Middleware } from '@nuxt/types'

const redirectIncompleteUrl: Middleware = ({ route, redirect }) => {
  // Visualisation de la regExp si besoin : https://jex.im/regulex/#!flags=img&re=%5E%5C%2Fassociations%5C%2F%5B%5E%5C%2F%5D%2B(%3F%3D%5C%2F(%3F!checkout)%5B%5E%5C%2F%5D%2B%5C%2F%3F%24)
  // assoPage récupère la part de l'url qui contient de nom de l'asso excluant le slash suivant.
  const assoPage = route.path.match(/^\/associations\/[^\/]+(?=\/(?!checkout)[^\/]+\/?$)/gmi)?.[0]

  if (assoPage) {
    redirect({ path: assoPage })
  }
}

export default redirectIncompleteUrl
