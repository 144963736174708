import { AxiosInstance, AxiosResponse } from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import type { FormCompliance } from '@/components/forms/forms.interface'
import type { Organization } from '../components/organizations/organizations.interface'
import type { DeepIndexes } from '../types/common.interface'

export const state = () => ({
  compliance: {} as DeepIndexes<FormCompliance>,
  organizations: {} as DeepIndexes<Organization>,
  provider: '' as string
})

export type OrganizationState = ReturnType<typeof state>

export const getters: GetterTree<OrganizationState, OrganizationState> = {
  getOrganization: state => (slug: string) => state.organizations[slug],
  getOrganizationCompliance: state => (slug: string) => state.compliance[slug],
  getProvider: state => state.provider
}

export const mutations: MutationTree<OrganizationState> = {
  async FETCH_ORGANIZATION(state, organization: Organization) {
    Object.assign(state.organizations, {
      [organization.organizationSlug]: organization
    })
  },
  FETCH_COMPLIANCE(state, { compliance, slug }: { compliance: FormCompliance; slug: string }) {
    Object.assign(state.compliance, {
      [slug]: compliance
    })
  },
  SET_PROVIDER(state, provider) {
    state.provider = provider
  }
}

export const actions: ActionTree<OrganizationState, OrganizationState> = {
  fetchOrganization({ commit }, slug: string) {
    const url = `/organizations/${slug}/full`

    // @ts-expect-error no import error
    return (this.$apiClient as AxiosInstance).get(url).then((response: AxiosResponse) => {
      commit('FETCH_ORGANIZATION', response.data)
      return response.data
    })
  },

}
