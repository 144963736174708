import { GeoPlace } from '~/types/common.interface'

export interface Payer {
  email: string
  firstname?: string
  firstName?: string
  lastname?: string
  lastName?: string
  place?: GeoPlace
  dateOfBirth?: string
  consent?: boolean
  companyName?: string
  companyLegalStatus?: number
  companySiren?: string
}

export type PayerByCard = Record<string, Payer>

export enum PAYER_ACTION  {
  SET_PAYER = 'SET_PAYER',
  UNSET_PAYER = 'UNSET_PAYER',
  PATCH_PAYER = 'PATCH_PAYER',
}