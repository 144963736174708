export const ErrorTypes = {
  TOKEN: 'server_error'
}

export const CampaignType = {
  FUNDRAISING: 'FUNDRAISING',
  EVENT: 'EVENT',
  MEMBERSHIP: 'MEMBERSHIP',
  CROWDFUNDING: 'CROWDFUNDING',
  PAYMENTFORM: 'PAYMENTFORM',
  SHOP: 'SHOP',
  UNKNOWN: 'UNKNOWN'
}

export const UrlToApiTypes = {
  boutiques: 'SHOP',
  evenements: 'EVENT',
  adhesions: 'MEMBERSHIP',
  collectes: 'CROWDFUNDING',
  paiements: 'PAYMENTFORM',
  formulaires: 'DONATION'
}

export const CouponStates = {
  PRISTINE: 'pristine',
  INVALID: 'invalid',
  VALID: 'valid'
}

export const itemType = {
  SHOP: 'Product',
  FUNDRAISING: 'Donation',
  EVENT: 'Registration',
  MEMBERSHIP: 'Membership',
  CROWDFUNDING: 'Donation',
  PAYMENTFORM: 'Payment',
  UNKNOWN: 'UNKNOWN'
}

export const PostPaymentStatutes = {
  CONFIRMATION: 'confirmation',
  CANCELLATION: 'annulation',
  FAILURE: 'echec'
}

export const NextActionStatus = {
  CONFIRM: 'Confirm',
  FINALIZE: 'Finalize',
  INITIALIZE: 'Initialize',
  REDIRECT: 'Redirect',
  RETRY: 'Retry'
}

export const PaymentErrorType = {
  INITIALIZE_HAPAY_ERROR: 'INITIALIZE_HAPAY_ERROR',
  INITIALIZE_HAPAY_JS_ERROR: 'INITIALIZE_HAPAY_JS_ERROR',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  CART_EXPIRED: 'CART_EXPIRED',
  AUTHENTICATION_FAILURE: 'AUTHENTICATION_FAILURE',
  REDIRECT_URL_EMPTY: 'REDIRECT_URL_EMPTY',
  REDIRECT_URL_EMPTY_WITH_CONFIRMED_PAYMENT: 'REDIRECT_URL_EMPTY_WITH_CONFIRMED_PAYMENT',
  PAYMENT_MAX_ERROR_ATTEMPTS: 'PAYMENT_MAX_ERROR_ATTEMPTS',
  PAYMENT_INITIALIZE_ERROR: 'PAYMENT_INITIALIZE_ERROR',
  PAYMENT_REFUSED: 'PAYMENT_REFUSED',
  DUPLICATED_PAYMENT: 'DUPLICATED_PAYMENT',
  DUPLICATE_PAYMENT_HA: 'DUPLICATE_PAYMENT_HA',
  PAYMENT_CANCELED: 'PAYMENT_CANCELED',
  SESSION_ALREADY_USED: 'SESSION_ALREADY_USED',
  EXECUTE_PAYMENT_ERROR: 'EXECUTE_PAYMENT_ERROR'
}

export const donationRequiredPersonalInformationsFields = [
  'firstName',
  'lastName',
  'email',
  'birthDate',
  'address',
  'zipCode',
  'city',
  'country'
]

export const donationRequiredCompanyFields = ['companyName', 'companySiren', 'companyLegalStatus']
