import Vue from 'vue'
import NuxtJsonld from 'nuxt-jsonld'
import nodeEnv from '@/helpers/nodeEnv.ts'

Vue.use(NuxtJsonld)

// Removes indentation in production to reduce bundle size
Vue.use(NuxtJsonld, {
  space: nodeEnv.isProduction ? 0 : 2 // default: 2
})
