import { useStore, useContext } from '@nuxtjs/composition-api'
import useNotifications from '@/composables/useNotifications'
import useStoreData from '@/composables/useStoreData'
import useTracking from '@/composables/useTracking'
import { useHeaderPublic, useSsrState } from '@ha/components'
import { AxiosError } from 'axios'
import { accessTokenCookieName, refreshTokenCookieName, sessionTokenCookieName } from '~/constants'


const isDisconnecting = useSsrState('isDisconnecting', () => false)
const isConnecting = useSsrState('isConnecting', () => false)

export default () => {
  // @ts-ignore
  const { $config, i18n, $cookies } = useContext()
  const store = useStore()
  const { onError } = useNotifications()
  const { storeRouteParams, user } = useStoreData()
  const { trackingTrack, trackingReset } = useTracking()
  const { setUser, setOrganizations, isLoadingUser } = useHeaderPublic()


  const onLogout = async () => {
    try {
      isDisconnecting.value = true
      await store.dispatch('user/disconnect')
      trackingTrack('Logout', {
        userId: user.value.userId
      })
      trackingReset()
      $cookies.remove(accessTokenCookieName)
      $cookies.remove(refreshTokenCookieName)
      $cookies.remove(sessionTokenCookieName)
      globalThis?.location?.reload()
    } catch (error: any) {
      onError(error)
    } finally {
      setUser(null)
      setOrganizations([])
      isDisconnecting.value = false
    }
  }

  const onLogin = async ({ username, password }: { username: string, password: string }) => {
    try {
      isConnecting.value = true
      isLoadingUser.value = true
      await store.dispatch('user/connect', [username, password, storeRouteParams.value])
    } catch (error: AxiosError | any) {
      if (
        error.response?.data?.message === 'password_expired' ||
        error.response?.data?.error?.message === 'password_expired'
      ) {
        window.location.href = `${$config.NUXT_ENV_AUTH_FRONT}/mot-de-passe/expired`
        return
      }
      onError(error, {
        title: i18n.t('modal.login.errors.login.title'),
        body: i18n.t('modal.login.errors.login.message')
      })
    } finally {
      setUser(store.getters['user/getMe']())
      setOrganizations(store.getters['user/getUserOrganizations']())
      isLoadingUser.value = false
      isConnecting.value = false
      globalThis?.location?.reload()
    }
  }

  return {
    isDisconnecting,
    isConnecting,
    onLogin,
    onLogout
  }
}
