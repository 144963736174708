import { CartState } from '~/store/carts'
import { CartUpdateValueArgs } from './carts.interface'

export const updateStoreCartError = {
  keyValue: () =>
    `value or key is undefined`,
  index: (cartItemIndex: any) =>
    `cartItemIndex '${cartItemIndex}' is not valid`,
  cart: (organizationSlug: string, formType: string, formSlug: string) =>
    `Cart organization '${organizationSlug}', type '${formType}' ans slug '${formSlug}' does not exist`,
  cartItem: (cartItemIndex: any) =>
    `Cart item index '${cartItemIndex}' does not exist`,
  customField: (customFieldIndex: any) =>
    `Custom field index '${customFieldIndex}' does not exist`,
  extraOption: (extraOptionIndex: any) =>
    `Extra option index '${extraOptionIndex}' does not exist`,
  extraOptionCustomField: (extraOptionIndex: any, customFieldIndex: any) =>
    `Custom field index '${customFieldIndex}' in extra option index '${extraOptionIndex}' does not exist`,
}

export const updateStoreCart = (state: CartState, args: CartUpdateValueArgs) => {
  const {
    organizationSlug,
    formType,
    formSlug,
    cartItemIndex,
    extraOptionIndex,
    customFieldIndex,
    key,
    value
  } = args

  // Prop will be update after check path to prop and value
  let willUpdateProp: any = undefined

  // Value
  if (value == undefined || key == undefined) {
    throw new Error(updateStoreCartError.keyValue())
  }

  // values for store deep index
  if (
    typeof organizationSlug !== 'string' &&
    typeof formType !== 'string' &&
    typeof formSlug !== 'string') {
    throw new Error(updateStoreCartError.cart(organizationSlug, formType, formSlug))
  }

  // Cart
  if (
    cartItemIndex == undefined &&
    extraOptionIndex == undefined &&
    customFieldIndex == undefined
  ) {
    willUpdateProp = state.carts?.[organizationSlug]?.[formType]?.[formSlug]
    if (!willUpdateProp) {
      throw new Error(updateStoreCartError.cart(organizationSlug, formType, formSlug))
    }
    willUpdateProp[key] = value
    return
  }


  // Cart > Item
  if (
    cartItemIndex != undefined && Number.isInteger(cartItemIndex) &&
    extraOptionIndex == undefined &&
    customFieldIndex == undefined
  ) {
    willUpdateProp = state.carts?.[organizationSlug]?.[formType]?.[formSlug]?.itemList?.[cartItemIndex]
    if (!willUpdateProp) {
      throw new Error(updateStoreCartError.cartItem(cartItemIndex))
    }
    willUpdateProp[key] = value
    return
  }

  // Cart > Item > Custom Field
  if (
    cartItemIndex != undefined && Number.isInteger(cartItemIndex) &&
    extraOptionIndex == undefined &&
    customFieldIndex != undefined && Number.isInteger(customFieldIndex)
  ) {
    willUpdateProp = state.carts?.[organizationSlug]?.[formType]?.[formSlug]?.itemList?.[cartItemIndex].customFields?.[customFieldIndex]
    if (!willUpdateProp) {
      throw new Error(updateStoreCartError.customField(customFieldIndex))
    }
    willUpdateProp[key] = value
    return
  }

  // Cart > Item > Extra option
  if (
    cartItemIndex != undefined && Number.isInteger(cartItemIndex) &&
    extraOptionIndex != undefined && Number.isInteger(extraOptionIndex) &&
    customFieldIndex == undefined
  ) {
    willUpdateProp = state.carts?.[organizationSlug]?.[formType]?.[formSlug]?.itemList?.[cartItemIndex].extraOptions?.[extraOptionIndex]
    if (!willUpdateProp) {
      throw new Error(updateStoreCartError.extraOption(extraOptionIndex))
    }
    willUpdateProp[key] = value
    return
  }

  // Cart > Item > Extra option > Custom Field
  if (
    cartItemIndex != undefined && Number.isInteger(cartItemIndex) &&
    extraOptionIndex != undefined && Number.isInteger(extraOptionIndex) &&
    customFieldIndex != undefined && Number.isInteger(customFieldIndex)
  ) {
    willUpdateProp = state.carts?.[organizationSlug]?.[formType]?.[formSlug]?.itemList?.[cartItemIndex].extraOptions?.[extraOptionIndex].customFields?.[customFieldIndex]
    if (!willUpdateProp) {
      throw new Error(updateStoreCartError.extraOptionCustomField(extraOptionIndex, customFieldIndex))
    }
    willUpdateProp[key] = value
    return
  }
}

