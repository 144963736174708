import { datadogLogs } from '@datadog/browser-logs'
import { Plugin } from '@nuxt/types'
import axiosClients from '../helpers/axios'

const axiosClient: Plugin = ({ app }, inject) => {
  const { $config, $cookies } = app
  const proxyHost = window.location.origin

 const { httpAuth, apiClient } = axiosClients({
    name: 'Client Side',
    cookies: $cookies,
    authBaseURL: $config.NUXT_ENV_AUTH_URL,
    authProxyBaseURL: `${proxyHost}/forms/auth`,
    apiBaseURL: $config.NUXT_ENV_BASE_API,
    apiProxyBaseURL: `${proxyHost}/forms/api`,
    logger: datadogLogs.logger,
    frontBaseUrl: $config.NUXT_ENV_BASE_URL
  })

  // inject to app context
  inject('httpAuth', httpAuth)
  inject('apiClient', apiClient)
}

export default axiosClient
