import { mapDataReturnedByAPI } from '@/helpers/formUtils'
import { PAYER_ACTION } from '~/components/payers/payer.interface'
import type { PayerByCard, Payer } from '~/components/payers/payer.interface'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { AxiosInstance } from 'axios'
import type { DeepGetterParams, FormsStoreRootState } from '~/types/common.interface'

export const state = () => ({
  payerByCart: {} as PayerByCard
})

export type PayerState = ReturnType<typeof state>

export const getters: GetterTree<PayerState, FormsStoreRootState> = {
  getPrefilledPayer: state => (cartId: string) => {
    return state.payerByCart[cartId]
  }
}

export const mutations: MutationTree<PayerState> = {
  [PAYER_ACTION.SET_PAYER](state, [payer, cartId]) {
    const savedPayer = state.payerByCart[cartId] ?? {}

    Object.assign(state.payerByCart, {
      [cartId]: {
        ...savedPayer,
        ...payer
      }
    })
  },
  [PAYER_ACTION.UNSET_PAYER](state, cartId) {
    delete state.payerByCart[cartId]
  }
}

export const actions: ActionTree<PayerState, FormsStoreRootState> = {
  setPayer({ commit }, [payer, cartId]) {
    commit(PAYER_ACTION.SET_PAYER, [payer, cartId])
    return Promise.resolve()
  },

  unsetPayer({ commit }, cartId) {
    commit(PAYER_ACTION.UNSET_PAYER, cartId)
    return Promise.resolve()
  },

  putPayer(
    { commit },
    [payer, { organization, slug, type }, cartId]: [Payer, DeepGetterParams, string]
  ) {
    // For testing purpose only => to be removed when api will have this parameter optionnal
    console.warn('[consent]: forced to true for testing purpose')
    const updatePayer = { ...payer }
    updatePayer.consent = true

    // @ts-expect-error import problem
    return (this.$apiClient as AxiosInstance)
      .put(`/carts/${cartId}/payer`, updatePayer)
      .then(response => {
        const data = mapDataReturnedByAPI(response.data)
        commit('carts/SET_CART', [data, { organization, slug, type }], {
          root: true
        })
        return data
      })
      .catch(e => {
        throw e
      })
  }
}
