<template>
  <div id="Layout-Payment">
    <ha-toaster ref="notifications" />
    <LayoutHeader />
    <main>
      <nuxt />
    </main>
  </div>
</template>

<script>
import { HaToaster } from '@ha/components'
import LayoutHeader from '@/components/layouts/LayoutHeader.vue'

export default {
  name: 'LayoutPayment',
  components: {
    HaToaster,
    LayoutHeader
  },
  provide() {
    return {
      $notifications: {
        push: this.pushToast
      },
      isWidget: false
    }
  },
  head() {
    return {
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex' }]
    }
  },
  methods: {
    pushToast(options, error) {
      this.$refs.notifications.push(options)
      if (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  min-height: 100vh;
  padding-top: var(--header-height);
}
</style>
