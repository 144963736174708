import localeeb77d04e from '../../src/locales/index.fr.js'
import localeeb97a248 from '../../src/locales/index.en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"fr","file":"index.fr.js","iso":"fr-FR","name":"Français"},{"code":"en","file":"index.en.js","iso":"en-GB","name":"English"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"fr","file":"index.fr.js","iso":"fr-FR","name":"Français"},{"code":"en","file":"index.en.js","iso":"en-GB","name":"English"}],
  localeCodes: ["fr","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "i",
  3: "n",
  4: "d",
  5: "e",
  6: "x",
  7: ".",
  8: "f",
  9: "r",
  10: ".",
  11: "j",
  12: "s",
  13: "\"",
  14: ":",
  15: "\"",
  16: ".",
  17: ".",
  18: "/",
  19: "s",
  20: "r",
  21: "c",
  22: "/",
  23: "l",
  24: "o",
  25: "c",
  26: "a",
  27: "l",
  28: "e",
  29: "s",
  30: "/",
  31: "i",
  32: "n",
  33: "d",
  34: "e",
  35: "x",
  36: ".",
  37: "f",
  38: "r",
  39: ".",
  40: "j",
  41: "s",
  42: "\"",
  43: ",",
  44: "\"",
  45: "i",
  46: "n",
  47: "d",
  48: "e",
  49: "x",
  50: ".",
  51: "e",
  52: "n",
  53: ".",
  54: "j",
  55: "s",
  56: "\"",
  57: ":",
  58: "\"",
  59: ".",
  60: ".",
  61: "/",
  62: "s",
  63: "r",
  64: "c",
  65: "/",
  66: "l",
  67: "o",
  68: "c",
  69: "a",
  70: "l",
  71: "e",
  72: "s",
  73: "/",
  74: "i",
  75: "n",
  76: "d",
  77: "e",
  78: "x",
  79: ".",
  80: "e",
  81: "n",
  82: ".",
  83: "j",
  84: "s",
  85: "\"",
  86: "}",
}

export const localeMessages = {
  'index.fr.js': () => Promise.resolve(localeeb77d04e),
  'index.en.js': () => Promise.resolve(localeeb97a248),
}
