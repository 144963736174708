export default {
  // TODO clean when abTest is archived
  abcdTestFourStepCV: {
    id: 1285371,
    variations: {
      a: 0,
      b: 1593683,
      c: 1593694,
      d: 1593695,
      e: 1636811
    }
  }
}
