<script setup>
import { ref } from 'vue'
import { HaButton, HaLabel, HaModal, HaFormGroup, HaInput, HaPasswordInput } from '@ha/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useLogin from '@/composables/useLogin'

const emit = defineEmits(['close', 'login'])

const { isConnecting, onLogin } = useLogin()

const username = ref('')
const password = ref('')
const validationObserver = ref(null)

const onSubmit = async () => {
  const isFormValid = await validationObserver.value.validate()
  if (!isFormValid) return
  await onLogin({ username: username.value, password: password.value })
  emit('login')
}
</script>

<template>
  <ValidationObserver ref="validationObserver" tag="div">
    <HaModal class="ModalLogin" @close="emit('close')">
      <template #header>
        {{ $t('modal.login.title') }}
      </template>
      <template #body>
        <form @keyup.enter="onSubmit">
          <HaFormGroup>
            <template #title>
              <HaLabel> {{ $t('modal.login.email') }}</HaLabel>
            </template>
            <ValidationProvider v-slot="{ valid, validated }" :rules="'EMAIL'" name="username" slim>
              <HaInput
                v-model="username"
                :is-valid="validated ? valid : null"
                data-test="input-email"
              />
            </ValidationProvider>
          </HaFormGroup>
          <HaFormGroup>
            <template #title>
              <HaLabel>
                {{ $t('modal.login.password') }}
              </HaLabel>
            </template>
            <ValidationProvider v-slot="{ valid, validated }" :rules="'REQUIRED'" slim>
              <HaPasswordInput v-model="password" :is-valid="validated ? valid : null" data-test="input-password" />
            </ValidationProvider>

            <div class="ModalLogin--CtaWrapper">
              <HaButton
                class="ModalLogin--ForgottenPassword"
                href="/utilisateur/recuperation-mot-de-passe"
                variant="link"
                size="small"
                data-ux="Forms_Modal_Login_ForgottenPassword"
                data-test="link-forgotten-password"
              >
                {{ $t('modal.login.forgotPassword') }}
              </HaButton>
            </div>
          </HaFormGroup>
        </form>
      </template>
      <template #footer>
          <HaButton
            :disabled="isConnecting"
            :loading="isConnecting"
            data-ux="Forms_Modal_Login_SignIn"
            data-test="button-connect"
            @click="onSubmit"
          >
            {{ $t('modal.login.connect') }}
          </HaButton>
      </template>
    </HaModal>
  </ValidationObserver>
</template>

<style lang="scss">
.ModalLogin {
  &--ForgottenPassword {
    display: inline-block;
    margin-top: $ha-spacing-mini;
    margin-bottom: $ha-spacing-tiny;
  }

  &--CtaWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: $ha-spacing-medium;
    justify-content: space-between;
    margin-top: $ha-spacing-medium;

    .HaButton {
      display: inline-block;
      margin-top: $ha-spacing-mini;
      margin-bottom: $ha-spacing-tiny;
      color: var(--ha-color-primary);
      font-weight: $ha-font-weight-semibold;
      font-size: $ha-font-size-tiny;

      &:hover,
      &:focus {
        color: var(--ha-color-primary);
        text-decoration: underline;
      }
    }
  }

  .FormGroup {
    padding-bottom: $ha-spacing-medium;
  }
}
</style>
