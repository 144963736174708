import atob from 'atob'
import type Cookies from 'universal-cookie'
import {
  accessTokenCookieName,
  orgAdmin as slugOrganizationAdmin,
  formAdmin as slugFormAdmin
} from '@/constants'

type AccessTokenInformations = {
  isExpired: boolean
  userId: string
  isUnverified: boolean
  hasUserId: boolean
  isAdmin: boolean
}

type DecodedAccessToken = {
  jti: string
  cps: string
  uid?: string
  urs?: string | Array<string>
  nbf: number
  exp?: number
  iss: string
  aud: string
  uvf?: string
}

export const getJwtInfo = (accessToken: string): AccessTokenInformations | undefined => {
  if (!accessToken || typeof accessToken === 'string' && accessToken === 'undefined') {
    console.warn('accessToken is undefined')
    return
  }

  if (typeof accessToken !== 'string') {
    console.warn('accessToken must be a string')
    return
  }

  const encodedPayload: string = accessToken.split('.')[1]
  if (!encodedPayload) {
    console.warn('Invalid accessToken')
    return
  }

  const accessTokenString = atob(encodedPayload)
  const decodedAccessToken: DecodedAccessToken = JSON.parse(accessTokenString)
  const utcTimestamp: number = Date.now().valueOf() / 1000

  const isExpired: boolean = decodedAccessToken.exp ? decodedAccessToken.exp < utcTimestamp : false

  const isAdmin: boolean =
    decodedAccessToken.urs?.includes(slugOrganizationAdmin) ||
    decodedAccessToken.urs?.includes(slugFormAdmin) ||
    false

  const isUnverified: boolean = decodedAccessToken.uvf === '1'
  const userId: string = decodedAccessToken.uid ?? ''
  const hasUserId: boolean = !!userId

  return {
    isExpired,
    userId,
    isUnverified,
    hasUserId,
    isAdmin
  }
}

export const getTokenInfo = (cookies: Cookies) => {
  const accessToken = cookies.get(accessTokenCookieName)
  if (!accessToken) return
  return getJwtInfo(accessToken)
}

export const isUserConnected = (cookies: Cookies) => {
  const tokenInfo = getTokenInfo(cookies)
  if (!tokenInfo) return
  return tokenInfo.hasUserId && !tokenInfo.isUnverified
}

/**
 * Get Bearer Authorization from existing access cookie
 */
export const getBearerFromCookies = (cookies: Cookies) => {
  const tokenInfo = getTokenInfo(cookies)
  if (!tokenInfo) return
  if (!tokenInfo.isExpired) {
    const accessToken = cookies.get(accessTokenCookieName)
    return `Bearer ${accessToken}`
  }
}
