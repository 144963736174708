<template>
  <div class="Error--Wrapper">
    <div class="Error--Message Container">
      <div class="Columns">
        <div class="Column Is-Half">
          <h1 class="Error--Title">
            {{ $t('error.code.500.title') }}
          </h1>
          <h2 class="Error--Subtitle">
            {{ $t('error.code.500.message') }}
          </h2>
          <p class="Error--Code">
            {{ $t('error.page.statusCode', ['500']) }}
          </p>
          <p>
            {{ $t('error.code.500.details') }}
          </p>
          <p>
            {{ $t('error.page.redirection_1') }}
            <a href="/contact" target="_blank" data-ux="Forms_Error_Contact">
              {{ $t('error.page.redirection_2') }}
            </a>
            {{ $t('error.page.redirection_3') }}
          </p>
          <client-only>
            <div class="Error--Buttons">
              <ha-button
                href="/"
                variant="outline"
                size="large"
                data-ux="Forms_Error_BackToHome"
              >
                {{ $t('error.page.backToHome') }}
              </ha-button>
              <ha-button
                size="large"
                data-ux="Forms_Error_GoBack"
                @click="$router.go(-1)"
              >
                {{ $t('error.page.back') }}
              </ha-button>
            </div>
          </client-only>
        </div>
      </div>
    </div>
    <div class="Error--Image" />
  </div>
</template>

<script>
import { HaButton } from '@ha/components'

export default {
  name: 'Error500',
  components: {
    HaButton
  }
}
</script>
