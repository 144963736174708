import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3bef69e0 = () => interopDefault(import('../src/pages/paiement/embarque/_id.vue' /* webpackChunkName: "" */))
const _163fb8f2 = () => interopDefault(import('../src/pages/associations/_organizationSlug/_formType/_campaignSlug/widget/_steps.vue' /* webpackChunkName: "" */))
const _56a5ef19 = () => interopDefault(import('../src/pages/associations/_organizationSlug/_formType/_campaignSlug/_steps.vue' /* webpackChunkName: "" */))
const _7f4f8ee2 = () => interopDefault(import('../src/pages/associations/_organizationSlug/_formType/_campaignSlug/checkout/_cartId.vue' /* webpackChunkName: "" */))
const _33605bbf = () => interopDefault(import('../src/components/payments/PaymentRedirect.vue' /* webpackChunkName: "" */))
const _07265a18 = () => interopDefault(import('../src/components/forms/widgets/WidgetVignetteHorizontale.vue' /* webpackChunkName: "" */))
const _bcde626e = () => interopDefault(import('../src/components/forms/widgets/WidgetCounter.vue' /* webpackChunkName: "" */))
const _52f3d13d = () => interopDefault(import('../src/components/forms/widgets/WidgetVignette.vue' /* webpackChunkName: "" */))
const _2657f1f5 = () => interopDefault(import('../src/components/forms/widgets/WidgetButton.vue' /* webpackChunkName: "" */))
const _737440ac = () => interopDefault(import('../src/pages/paiement/embarque/confirmation/_id.vue' /* webpackChunkName: "pages/paiement/embarque/confirmation/_id" */))
const _0911f45c = () => interopDefault(import('../src/pages/paiement/regularisation/embarque/_id.vue' /* webpackChunkName: "pages/paiement/regularisation/embarque/_id" */))
const _8c18e7ce = () => interopDefault(import('../src/pages/paiement/regularisation/_id.vue' /* webpackChunkName: "pages/paiement/regularisation/_id" */))
const _bfd583ca = () => interopDefault(import('../src/pages/paiement/regularisation/_status/_id.vue' /* webpackChunkName: "pages/paiement/regularisation/_status/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/paiement/embarque-ha/:id?",
    component: _3bef69e0,
    name: "HaPayRoute"
  }, {
    path: "/associations/:organizationSlug?/:formType(formulaires)?/:campaignSlug?/widget/en",
    component: _163fb8f2,
    name: "EnglishRouteWidget"
  }, {
    path: "/associations/:organizationSlug?/:formType(formulaires)?/:campaignSlug?/en",
    component: _56a5ef19,
    name: "EnglishRoute"
  }, {
    path: "/associations/:organizationSlug?/:formType(checkout)?/:cartId",
    component: _7f4f8ee2,
    name: "Checkout"
  }, {
    path: "/associations/:organizationSlug?/checkout/:cartId?/redirection",
    component: _33605bbf,
    name: "PaymentRedirectCheckout"
  }, {
    path: "/associations/:organizationSlug?/:formType?/:campaignSlug?/redirection",
    component: _33605bbf,
    name: "PaymentRedirectForm"
  }, {
    path: "/redirection",
    component: _33605bbf,
    name: "PaymentRedirect"
  }, {
    path: "/associations/:organizationSlug?/:formType?/:campaignSlug?/widget-vignette-horizontale",
    component: _07265a18,
    name: "widgetVignetteHorizontale"
  }, {
    path: "/associations/:organizationSlug?/:formType?/:campaignSlug?/widget-compteur",
    component: _bcde626e,
    name: "widgetCounter"
  }, {
    path: "/associations/:organizationSlug?/:formType?/:campaignSlug?/widget-vignette",
    component: _52f3d13d,
    name: "widgetVignette"
  }, {
    path: "/associations/:organizationSlug?/:formType?/:campaignSlug?/widget-bouton",
    component: _2657f1f5,
    name: "widgetButton"
  }, {
    path: "/paiement/embarque/confirmation/:id?",
    component: _737440ac,
    name: "paiement-embarque-confirmation-id"
  }, {
    path: "/paiement/regularisation/embarque/:id?",
    component: _0911f45c,
    name: "paiement-regularisation-embarque-id"
  }, {
    path: "/paiement/embarque/:id?",
    component: _3bef69e0,
    name: "paiement-embarque-id"
  }, {
    path: "/paiement/regularisation/:id?",
    component: _8c18e7ce,
    name: "paiement-regularisation-id"
  }, {
    path: "/paiement/regularisation/:status?/:id?",
    component: _bfd583ca,
    name: "paiement-regularisation-status-id"
  }, {
    path: "/associations/:organizationSlug?/:formType?/:campaignSlug?/checkout/:cartId?",
    component: _7f4f8ee2,
    name: "associations-organizationSlug-formType-campaignSlug-checkout-cartId"
  }, {
    path: "/associations/:organizationSlug?/:formType?/:campaignSlug?/widget/:steps?",
    component: _163fb8f2,
    name: "associations-organizationSlug-formType-campaignSlug-widget-steps"
  }, {
    path: "/associations/:organizationSlug?/:formType?/:campaignSlug?/:steps?",
    component: _56a5ef19,
    name: "associations-organizationSlug-formType-campaignSlug-steps"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
