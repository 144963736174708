<template>
  <div id="Layout-Checkout">
    <ha-toaster ref="notifications" />
    <nuxt />
  </div>
</template>

<script>
import { HaToaster } from '@ha/components'

export default {
  name: 'LayoutCheckout',
  components: {
    HaToaster
  },
  provide() {
    return {
      $notifications: {
        push: this.pushToast
      },
      isWidget: false
    }
  },
  methods: {
    pushToast(options) {
      this.$refs.notifications.push(options)
      if (error) {
        console.error(error)
      }
    }
  }
}
</script>
