import Vue from 'vue'
import { get, reduce, size, sumBy, toUpper, assign, omit, unset, find, filter } from 'lodash-es'
import { DateOperator, functions } from '@ha/helpers'
import { mapDataReturnedByAPI, mapDataSentToApi } from '../helpers/formUtils'
import { cartLifespanMinutes } from '../constants'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { FormState } from './forms'
import { AxiosResponse } from 'axios'
import type {
  Cart,
  CartItem,
  CartUpsertPayload,
  CartUpsertResponse,
  CartUpdateValueArgs,
  Coupon
} from '../components/carts/carts.interface'
import { CartsGetters, CartsAction } from '../components/carts/carts.interface'
import type {
  CollectionOf,
  DeepIndexes,
  DeepGetterParams,
  FormsStoreRootState
} from '../types/common.interface'
import { getRemainingStock } from '@/components/extra-options/extraOptions.helpers'
import type { ExtraOption } from '@/components/extra-options/extraOptions.interface'
import type { TierMistack } from '@/components/tiers/tiers.interface'
import { updateStoreCart } from '@/components/carts/carts.helpers'

export const state = (): {
  carts: DeepIndexes<Cart | null>
  currentCartExpirationTime: number
  tips?: DeepIndexes<any | undefined>
  coupons?: Coupon
  paiementStatus?: string // undefined | canceled | error
  errorCode?: string | number // undefined | ORGANIZATION_WALLET_ERROR | 400 | 404 | 409
} => ({
  carts: {} as DeepIndexes<Cart>,
  currentCartExpirationTime: 0,
  tips: {},
  coupons: {},
  paiementStatus: undefined, // undefined | canceled | error
  errorCode: undefined // undefined | ORGANIZATION_WALLET_ERROR | 400 | 404 | 409
})

export type CartState = ReturnType<typeof state>

export const getters: GetterTree<CartState, FormsStoreRootState> = {
  getGtmData:
    (state, _, rootState) =>
    (step: unknown, { organization, type, slug }: DeepGetterParams) => {
      const cart = state.carts[organization]?.[type]?.[slug]
      const transactionTip = parseFloat(
        functions.convertToEuros(state.tips?.[organization]?.[type]?.[slug]?.tip || 0)
      )
      const tierLabels: CollectionOf<string> = {}
      const items: Array<TierMistack | CartItem> = []
      const options: ExtraOption[] = []

      cart?.tierList.forEach(tier => {
        // @ts-ignore
        tierLabels[tier.id] = tier.label
      })

      cart?.itemList.forEach(cartItem => {
        if (cartItem.tierType !== 'Donation') {
          ;(cartItem?.extraOptions || []).forEach(option => {
            if (option.value) {
              const currentOption = find(options, {
                tierId: cartItem.tierId,
                id: option.id
              })

              if (currentOption) {
                // refacto - check how use quantity or remaingNumber
                // @ts-ignore
                currentOption.quantity++
              } else {
                options.push({
                  // @ts-ignore
                  tierId: cartItem.tierId,
                  id: option.id,
                  label: option.label,
                  price: option.price,
                  quantity: 1
                })
              }
            }
          })

          const currentItem = items.find(item => item.id === cartItem.tierId)

          if (currentItem) {
            // @ts-ignore
            currentItem.quantity++
          } else {
            items.push({
              id: cartItem.tierId,
              label: tierLabels[cartItem.tierId as any],
              // @ts-ignore
              category: type,
              quantity: 1,
              price: parseFloat(functions.convertToEuros(cartItem.customAmount)),
              options: filter(options, { tierId: cartItem.tierId })
            })
          }
        }
      })

      const organizationData = rootState?.organizations.organizations[organization]
      return {
        ecommerce: {
          currencyCode: 'EUR',
          actionField: {
            step: step
          },
          checkout: {
            userId: rootState.user.profile.userId,
            userCat: cart?.payer?.companyName ? 'ORG' : 'ASSO',
            transactionId: cart?.id,
            transactionAffiliation: 'HelloAsso',
            category: type,
            transactionTotal:
              parseFloat(functions.convertToEuros(cart?.paymentTerms[0].realAmount || 0)) +
              transactionTip,
            transactionTax: transactionTip, // donation to HelloAsso
            transactionProducts: items
          },
          organization: {
            category: organizationData?.category,
            city: organizationData?.city,
            authenticationState: organizationData?.authenticationState,
            name: organizationData?.name,
            slug: organizationData?.organizationSlug,
            zipCode: organizationData?.zipCode
          }
        }
      }
    },
  // refacto - 9 : Get from Store by org + type + slug
  getCart:
    state =>
    ({ organization, slug, type }: DeepGetterParams) => {
      return state.carts[organization]?.[type]?.[slug]
    },
  getCartCoupon: state => (cartId: any) => {
    return get(state.coupons, cartId, undefined)
  },
  getDiscount:
    state =>
    ({ organization, slug, type }: DeepGetterParams) => {
      const result = state.carts?.[organization]?.[type]?.[slug]
      return sumBy(result?.paymentTerms, 'discount')
    },
  getRealAmount:
    state =>
    ({ organization, slug, type }: DeepGetterParams) => {
      const result = state.carts?.[organization]?.[type]?.[slug]
      return result?.paymentTerms[0].realAmount ?? 0
    },
  getErrorCode: state => () => state.errorCode,
  getStatus: state => () => state.paiementStatus,
  getCartTotal:
    state =>
    ({ organization, slug, type }: DeepGetterParams) => {
      const cart = state.carts?.[organization]?.[type]?.[slug]
      const tip = state.tips?.[organization]?.[type]?.[slug]?.tip || 0
      const itemList = cart?.itemList || []

      const total = itemList.reduce((acc: number, item) => {
        // add extra options amount
        const options = item.extraOptions || []
        let optionsAmount = 0
        options.forEach(option => {
          // get all initial amount of the payment terms
          const amount = sumBy(option.paymentTerms, 'initialAmount')
          optionsAmount = optionsAmount + amount
        })

        const paymentTerms = item.paymentTerms
        // get reduction from promo codes and decrement the discount of the ticket price
        if (paymentTerms && paymentTerms?.length > 0) {
          return reduce(
            paymentTerms,
            (termAcc: any, term: any) =>
              acc + (get(term, 'initialAmount') - get(term, 'discount')) + optionsAmount,
            0
          )
        }

        // add ticket price + selected amount price
        return acc + get(item, 'customAmount', 0) + optionsAmount
      }, 0)
      return total + tip
    },
  getTotalInscriptionsAndDonation:
    state =>
    ({ organization, slug, type }: DeepGetterParams) => {
      const cart = get(state.carts, [organization, type, slug])
      const itemList = get(cart, 'itemList', [])

      return reduce(
        itemList,
        (acc: number, item: any) => {
          const paymentTerms = get(item, 'paymentTerms')
          // get reduction from promo codes and decrement the discount of the ticket price
          if (size(paymentTerms) > 0) {
            return reduce(
              paymentTerms,
              (termAcc: any, term: any) =>
                acc + (get(term, 'initialAmount') - get(term, 'discount')),
              0
            )
          }

          // add ticket price + selected amount price
          return acc + get(item, 'customAmount', 0)
        },
        0
      )
    },
  getTip:
    state =>
    ({ organization, slug, type }: DeepGetterParams) => {
      return state.tips?.[organization]?.[type]?.[slug]?.tip || 0
    },
  getVat:
    state =>
    ({ organization, slug, type }: DeepGetterParams) => {
      const result = state.carts?.[organization]?.[type]?.[slug]
      return result?.paymentTerms[0].vat ?? 0
    },
  [CartsGetters.SELECTED_EXTRA_OPTIONS_BY_CART_ITEM]:
    (state, getters, rootState) =>
    ({ organization, slug, type }: DeepGetterParams) => {
      const cartItems = state.carts[organization]?.[type]?.[slug]?.itemList
      const TierItems = (rootState.forms as FormState).forms[organization]?.[type]?.[slug]?.tiers
      const selected: CollectionOf<number[]> = {}
      if (cartItems?.length) {
        for (const cartItem of cartItems) {
          const tier = TierItems.find(tier => tier.id == cartItem.tierId)
          const cartItemIndex = cartItems.indexOf(cartItem)
          selected[cartItemIndex] = selected[cartItemIndex] || []
          for (const option of cartItem.extraOptions || []) {
            const FormTierExtraOption = tier?.extraOptions?.find(
              extraOption => extraOption.id == option.id
            )
            // refacto remaining
            const remainingNumber = FormTierExtraOption
              ? getRemainingStock(FormTierExtraOption, Object.values(selected).flat())
              : null
            const isPreSelected =
              option.isRequired && (remainingNumber === null || remainingNumber > 0)

            if (option.value || isPreSelected) selected[cartItemIndex].push(option.id)
          }
        }
      }
      return selected
    },
  [CartsGetters.SELECTED_EXTRA_OPTIONS]:
    (state, getters, rootState) =>
    ({ organization, slug, type }: DeepGetterParams) => {
      const selectedExtraOptions = getters[CartsGetters.SELECTED_EXTRA_OPTIONS_BY_CART_ITEM]({
        organization,
        slug,
        type
      })
      return Object.values(selectedExtraOptions).flat()
    }
}

export const mutations: MutationTree<CartState> = {
  [CartsAction.SET_CART_META](
    state,
    { meta, storeRouteParams: { organization, slug, type } }: any
  ) {
    const cart = state.carts[organization][type][slug]
    if (cart) cart.meta = meta
  },
  /**
   * @param cart The cart payload as received from the API
   * @param formKey The generated key that will link the Cart to the Form.
   *  Key is : `${campaignType}:${organization}:${campaignName}`
   *
   * Using a deterministic key instead of an ID in order to be able to quickly retrieve carts
   * from cache with only the URL as input.
   */
  [CartsAction.SET_CART](state, [cart, { organization, slug, type }]: any) {
    const newRef = {
      [type]: {
        [slug]: {
          ...cart
        }
      }
    }
    const { updatedAt, createdAt } = cart.meta
    const serverDelayInSecondes = new DateOperator().diff(updatedAt || createdAt, 'seconds')
    state.currentCartExpirationTime =
      (cartLifespanMinutes * 60 - serverDelayInSecondes) * 1000 + Date.now()

    Vue.set(state.carts, organization, newRef)
  },
  [CartsAction.SET_COUPON](state, [cartId, coupon]: any) {
    assign(state, {
      coupons: {
        ...get(state, 'coupons'),
        [cartId]: coupon
      }
    })
  },
  [CartsAction.UNSET_COUPON](state, cartId: any) {
    assign(state, {
      coupons: {
        ...omit(get(state, 'coupons'), [cartId])
      }
    })
  },
  [CartsAction.REMOVE_CART](state, { organization, slug, type }: DeepGetterParams) {
    if(state.carts?.[organization]?.[type]?.[slug]) {
      state.carts[organization][type][slug] = null
    }
  },
  [CartsAction.SET_PAIEMENT_STATUS](state, status: any) {
    assign(state, {
      paiementStatus: status
    })
  },
  [CartsAction.SET_PAIEMENT_ERROR_CODE](state, errorCode: any) {
    assign(state, {
      errorCode
    })
  },
  [CartsAction.SET_TIP](state, [tip, { organization, slug, type }]: [unknown, DeepGetterParams]) {
    assign(state.tips, {
      [organization]: {
        [type]: {
          [slug]: {
            tip: tip
          }
        }
      }
    })
    // reactivity was not workin so... we reassign from new object then it worked
    // https://vuejs.org/v2/guide/reactivity.html
    state.tips = Object.assign({}, state.tips)
  },
  [CartsAction.UNSET_TIP](state, { organization, slug, type }: DeepGetterParams) {
    unset(state.tips, [organization, type, slug, 'tip'])
  },

  /**
   *
   * @param state Update cart extra option or custom field
   * @param args
   */
  [CartsAction.UPDATE_VALUE](state, args: CartUpdateValueArgs) {
    updateStoreCart(state, args)
  }
}

export const actions: ActionTree<CartState, FormsStoreRootState> = {
  async refreshCartSession ({ commit, dispatch }, { storeRouteParams, cartId }) {
    const response = await dispatch('refreshCartSessionById', { cartId })
    const { meta } = response.data

    commit(CartsAction.SET_CART_META, { meta, storeRouteParams })
  },
  refreshCartSessionById (_: any, { cartId }: any) {
    // @ts-ignore
    return (this.$apiClient as AxiosInstance).post(`/carts/${cartId}/refresh`)
  },
  // refacto - 4.a : POST CART
  postCart({ commit }, [payload, { organization, slug, type }]) {
    // refacto - 5 : BUILD PAYLOAD
    const cartPayload = {
      items: mapDataSentToApi(payload),
      organizationSlug: organization,
      formSlug: slug,
      formType: type
    }

    // refacto - 6 : CALL API
    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .post('/carts', cartPayload)
      .then((response: AxiosResponse<CartUpsertResponse>) => {
        // If the cart has been 'refreshed', unset the coupon as it might not be valid anymore.
        commit(CartsAction.UNSET_COUPON, get(response.data, 'id'))

        // refacto - 7 : DTO RES API
        const data = mapDataReturnedByAPI(response.data)

        // refacto - 8 : Save to Store
        commit(CartsAction.SET_CART, [data, { organization, slug, type }])

        return data
      })
      .catch((e: any) => {
        // NOTE : more consumers are awaiting this promise resolution with their own success/fail handlers
        // return the error since otherwise the following `catch` calls would not be called.
        // refacto - unaccepted pattern { ...e }
        throw { ...e, routeCode: 'POST /carts' }
      })
  },

  postCartOnePage(_: any, payload: any) {
    const cartPayload = {
      ...payload,
      items: mapDataSentToApi(payload.items)
    }

    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .post(`/carts/cart-one-page?backUrl=${location.href}`, cartPayload)
      .then((response: AxiosResponse) => {
        const data = mapDataReturnedByAPI(response.data)

        return data
      })
      .catch((e: any) => {
        // NOTE : more consumers are awaiting this promise resolution with their own success/fail handlers
        // return the error since otherwise the following `catch` calls would not be called.
        throw e
      })
  },

  /**
   * Applies the `coupon` code onto the `cartId` cart. Updates the cart after the call.
   * @param commit
   * @param coupon
   * @param cartId
   * @return {PromiseLike<any>}
   */
  applyCoupon({ commit }, [coupon, { organization, slug, type }, cartId]) {
    const cartPayload = {
      discountCode: toUpper(coupon)
    }
    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .post(`/carts/${cartId}/discount-code`, cartPayload)
      .then((response: AxiosResponse) => {
        commit(CartsAction.SET_COUPON, [cartId, coupon])
        const cart = mapDataReturnedByAPI(response.data)
        commit(CartsAction.SET_CART, [cart, { organization, slug, type }])
        return cart
      })
      .catch((e: any) => {
        throw e
      })
  },

  removeCoupon({ commit }, [{ organization, slug, type }, cartId]) {
    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .delete(`/carts/${cartId}/discount-code`)
      .then((response: AxiosResponse) => {
        commit(CartsAction.UNSET_COUPON, [cartId])
        const cart = mapDataReturnedByAPI(response.data)
        commit(CartsAction.SET_CART, [cart, { organization, slug, type }])
        return cart
      })
      .catch((e: any) => {
        throw e
      })
  },

  // refacto - 4.b : PUT CART
  /**
   * Takes in a `payload` and `cartId`. Payload is the distilled & flattened model used in the Ticketing forms.
   * Due to API limitations, the only way to update the model is to patch it client side and send a full model
   * to the API. This should (must) be simplified.
   * @param commit
   * @param payload The payload (contents of the `items` field) : tickets
   * @param cartId The cart id to update
   * @returns {PromiseLike<any>}
   */
  putCart(
    { commit, dispatch },
    [payload, { organization, slug, type }, id]: [CartItem[], DeepGetterParams, string]
  ) {
    const cartPayload: CartUpsertPayload = {
      organizationSlug: organization,
      formSlug: slug,
      formType: type,
      items: mapDataSentToApi(payload)
    }

    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .put(`/carts/${id}`, cartPayload)
      .then((response: AxiosResponse<CartUpsertResponse>) => {
        const data = mapDataReturnedByAPI(response.data)
        commit(CartsAction.SET_CART, [data, { organization, slug, type }])

        return data
      })
      .catch((error: any) => {
        const status = get(error, 'response.status')
        if (status === 404) {
          // fallback in case expiration timer fails
          return dispatch('removeCart', { organization, slug, type })
        }
        throw { ...error, routeCode: 'PUT /carts' }
      })
  },

  /**
   * Send request to update one page cart id
   * @param payload The payload (contents of the `items` field)
   * @param cartId The cart id to update
   * @returns {PromiseLike<any>}
   */
  putCartOnePage(_: any, { payload, cartId }: any) {
    const cartPayload = {
      ...payload,
      items: mapDataSentToApi(payload.items)
    }

    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .put(`/carts/cart-one-page/${cartId}?backUrl=${location.href}`, cartPayload)
      .then((response: AxiosResponse) => {
        const data = mapDataReturnedByAPI(response.data)
        return data
      })
      .catch((e: any) => {
        throw e
      })
  },
  /**
   * Locally removes a cart (right now there is no DELETE api endpoint).
   * Used when re-hydrating a local cart after a failed (HTTP-400) `fetchCart`. The newly posted cart will
   * receive a new ID, the previously stored one (in localstorage) should be removed as it is now inactive.
   * @param commit
   * @param getters
   * @param cartId
   */
  removeCart({ commit, getters, dispatch }, { organization, slug, type }) {
    return Promise.resolve().then(() => {
      const localCart = getters.getCart({
        organization,
        slug,
        type
      })
      const id = get(localCart, 'id')
      commit(CartsAction.REMOVE_CART, { organization, slug, type })
      commit(CartsAction.UNSET_COUPON, id)
      commit(CartsAction.UNSET_TIP, { organization, slug, type })
      dispatch('payer/unsetPayer', id, { root: true })
    })
  },

  /**
   * Sets a tip on the cart matching the cartId
   * NOTE: the tip should be in cents (ofc)
   * @param commit
   * @param getters
   * @param tip
   * @param id
   */
  putTip({ commit }, [tip, { organization, slug, type }, id]) {
    const tipPayload = {
      tip: Number(tip).toFixed()
    }

    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .put(`/carts/${id}/tip`, tipPayload)
      .then((response: AxiosResponse) => {
        const data = mapDataReturnedByAPI(response.data)
        commit(CartsAction.SET_CART, [data, { organization, slug, type }])
        return data
      })
  },

  async putPayer({ commit }, { id, payer, metaData }) {
    try {
      // @ts-ignore
      const response = await (this.$apiClient as AxiosInstance).put(`/carts/${id}/payer`, payer)
      commit(CartsAction.SET_CART, [mapDataReturnedByAPI(response.data), metaData])
      return response.data
    } catch (error: any) {
      throw { ...error, routeCode: 'PUT /payer' }
    }
  },

  /**
   * initialize a payment for the given cartId
   * @param commit
   * @param id is the cart id
   * expects 404 when cart is obsolete, 401 in case of session obsolete , 409 if cart is
   */
  initPayment({ commit }: any, id: any) {
    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .post(`/carts/${id}/init-payment?backUrl=${location.href}`)
      .then((response: AxiosResponse) => response.data)
      .catch((e: any) => {
        console.error('[iniPayment] received an error.', e)
        commit(CartsAction.SET_PAIEMENT_STATUS, 'error')
        commit(CartsAction.SET_PAIEMENT_ERROR_CODE, `${get(e, 'response.status')}`)
        throw { ...e, routeCode: 'POST /carts/init-payment' }
      })
  },

  /**
   * Set payment status
   * @param commit
   * @param status (the payment status provided by payment system)
   * @param errorCode (the payment error code if any)
   */
  setPaiementStatus({ commit }, [status, errorCode]) {
    commit(CartsAction.SET_PAIEMENT_STATUS, status)
    commit(CartsAction.SET_PAIEMENT_ERROR_CODE, errorCode)
  },

  // refacto - 3 : create or update forms
  createOrUpdateCart(
    store: { dispatch: (arg0: string, arg1: any[]) => Promise<any> },
    [formData, metadata, cartId]: any
  ) {
    const tickets = get(formData, 'itemList')

    // post if no cart exists, put if updating an existing cart
    if (cartId) {
      return store
        .dispatch('putCart', [tickets, metadata, cartId])
        .then((cart: any) => {
          return cart
        })
        .catch((error: any) => {
          throw error
        })
    }

    return store
      .dispatch('postCart', [tickets, metadata])
      .then((cart: any) => {
        return cart
      })
      .catch((error: any) => {
        throw error
      })
  },

  // eslint-disable-next-line no-empty-pattern
  uploadFile(_: any, [id, file]: any) {
    var formData = new FormData()
    formData.append('file', file)

    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .post(`/carts/${id}/file`, formData)
      .then((response: AxiosResponse) => response.data)
      .catch((e: any) => {
        throw e
      })
  },

  sendProspect(_: any, { metadata, email }: any): any {
    const { organization, slug, type } = metadata

    // @ts-ignore
    return (this.$apiClient as AxiosInstance)
      .post(`/carts/prospects`, {
        formSlug: slug,
        organizationSlug: organization,
        formType: type,
        email
      })
      .then((response: AxiosResponse) => response.data)
      .catch((error: any) => {
        throw error
      })
  },

  [CartsAction.UPDATE_VALUE]({ commit }, args) {
    commit(CartsAction.UPDATE_VALUE, args)
  },

  async putInstallments({ commit }, { id, installments, storeRouteParams }) {
    // @ts-ignore
    const response = await (this.$apiClient as AxiosInstance).put(`/carts/${id}/installments`, {
      installments
    })

    commit(CartsAction.SET_CART, [mapDataReturnedByAPI(response.data), storeRouteParams])
  }
}
