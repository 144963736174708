<template>
  <header class="HeaderLight">
    <HelloAssoLogo class="Header--Logo" data-ux="Forms_Header_Logo_BackToHome" />
  </header>
</template>

<script>
import HelloAssoLogo from '@/components/globals/logo/HelloAssoLogo.vue'

export default {
  name: 'HeaderLight',
  components: {
    HelloAssoLogo
  }
}
</script>

<style lang="scss">
.HeaderLight {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 899; // Behind HaModal (900)
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--header-height);
  background-color: var(--ha-color-white);
  box-shadow: $ha-box-shadow-middle;
}
</style>
