import createPersistedState from 'vuex-persistedstate'

export default ({ store, isHMR }) => {
  if (isHMR) return
  if (process.browser) {
    window.onNuxtReady(() => {
      createPersistedState({
        storage: window.sessionStorage,
        key: 'ha-forms',
        paths: [
          'carts.carts',
          'carts.coupons',
          'carts.tips',
          'carts.currentCartExpirationTime',
          'payer.payerByCart'
        ]
      })(store)
    })
  }
}
