import { Tracking, functions } from '@ha/helpers'
import { useSsrState } from '@ha/components'
import { ref, useContext, useStore, useRoute } from '@nuxtjs/composition-api'
import useStoreData from '@/composables/useStoreData'
import useFormType from '@/composables/useFormType'
import useRouteParams from '@/composables/useRouteParams'
import { TipSuggestions } from '~/components/voluntary-contributions/volontaryContribution.interface'
import { ITracking, TrackingData, TrackingIdentify, TrackingPage } from './useTracking.interface'

let tracking: null | ITracking = null
const hasFirstClick = useSsrState('hasFirstClick', () => ({}))

export default () => {
  const { $config } = useContext()
  const store = useStore()
  const { storeRouteParams } = useStoreData()
  const route = useRoute()
  const { isCheckout } = useFormType()
  const { formType } = useRouteParams()

  const canUseTracking = () => {
    if (tracking) return true
    console.warn('Tracking Segment is disabled on server side')
    return false
  }

  /* Because we don't have access to process.env.SEGMENT_KEY in client side,
  we have to instantiate it with $config.SEGMENT_KEY */
  if (!tracking && process.client) {
    tracking = new Tracking({
      segmentKey: process.env.SEGMENT_KEY || $config.SEGMENT_KEY,
      enabled: process.env.SEGMENT_ENABLED || $config.SEGMENT_ENABLED
    })
  }
  // Form tracking
  const form = store.getters['forms/getForm'](storeRouteParams.value)

  const formInfo = ref<TrackingData>({
    form_slug: form?.formSlug,
    form_type: isCheckout.value ? formType.value : form?.formType,
    form_name: form?.title,
    form_state: form?.state,
    organization_slug: storeRouteParams?.value.organization
  })

  const onFormStepChange = (customData: TrackingData) => {
    if (!canUseTracking()) return
    tracking?.track('Campaign Step completed', {
      ...formInfo?.value,
      ...customData
    })
  }

  const onFormSubmission = (overWriteTipData = null) => {
    if (!canUseTracking()) return
    const cart = store.getters['carts/getCart'](storeRouteParams.value)
    const { realAmount } = cart.paymentTerms[0]
    //* A refactor of the cart data structure would allow to use the same pattern to get tipData from a Donation and Vente as for any other product
    const tipData = overWriteTipData !== null ? overWriteTipData : cart.tipData
    const contributionType = tipData.tipSuggestion === tipData.tipChosen ? 'Default' : 'Custom'
    const cartData: TrackingData = {
      payment_amount: parseFloat(functions.convertToEuros(realAmount)).toFixed(2),
      contribution: tipData.tipChosen ? 'Yes' : 'No',
      contribution_type: tipData.tipChosen ? contributionType : 'No',
      contribution_default_amount: parseFloat(
        functions.convertToEuros(tipData.tipSuggestion)
      ).toFixed(2),
      contribution_amount: parseFloat(functions.convertToEuros(tipData.tipChosen)).toFixed(2)
    }

    tracking?.track('Campaign Payment Submitted', {
      ...formInfo.value,
      ...cartData
    })
  }

  const onFirstClick = (eventName: string, data: any = {}) => {
    if (!canUseTracking()) return
    const { form_slug } = data
    if (!hasFirstClick.value[form_slug]) {
      tracking?.track(eventName, {
        ...data,
        ...formInfo.value
      })
      hasFirstClick.value[form_slug] = true
    }
  }

  const onTipsUpdate = (
    eventTitle: string,
    overWriteTipData: TipSuggestions | null = null,
    customData: TrackingData = {}
  ) => {
    if (!canUseTracking()) return
    // we overwrite anyway but in case of futur updates we avoid to crash checkout
    const cart = isCheckout.value ? store.getters['checkout/getCart'](route.value.params.cartId) : store.getters['carts/getCart'](storeRouteParams.value)
    //* A refactor of the cart data structure would allow to use the same pattern to get tipData from a Donation and Vente as for any other product
    const tipData = overWriteTipData ?? cart.tipData
    const hasTip = Number(tipData.tipChosen) > 0
    const tipSelected = tipData.tipChosen ?? tipData.tipSuggestion
    const contributionType = tipData.tipSuggestion === tipData.tipChosen ? 'Default' : 'Custom'
    const totalPayementAmount = cart
      ? parseFloat(
        functions.convertToEuros(cart.paymentTerms.length * cart.paymentTerms[0].realAmount)
      ).toFixed(2)
      : ''
    const totalContributionAmount = cart
      ? parseFloat(functions.convertToEuros(cart.paymentTerms.length * tipSelected)).toFixed(2)
      : ''

    const data: TrackingData = {
      payment_amount: totalPayementAmount,
      contribution: hasTip ? 'Oui' : 'Non',
      contribution_type: hasTip ? contributionType : 'Non',
      contribution_default_amount: totalContributionAmount,
      contribution_amount: totalContributionAmount,
      payment_frequency_type: cart?.paymentTerms.length
    }
    // for some case customData properties overwrite ones from data
    // and bc checkout is... special, we need to delete them
    if (isCheckout.value) {
      delete customData?.payment_frequency_type
    }

    tracking?.track(eventTitle, { ...formInfo.value, ...data, ...customData })
  }

  const trackingTrack = (...args: [string, TrackingData]) => {
    if (!canUseTracking()) return
    tracking?.track(...args)
  }

  const trackingReset = () => {
    if (!canUseTracking()) return
    tracking?.reset()
  }

  const trackingIdentify = (...args: [number, TrackingIdentify]) => {
    if (!canUseTracking()) return
    tracking?.identify(...args)
  }

  const trackingPage = (...args: [string, TrackingPage]) => {
    if (!canUseTracking()) return
    tracking?.page(...args)
  }

  return {
    tracking,
    trackingTrack,
    trackingReset,
    trackingIdentify,
    trackingPage,
    trackingMethods: {
      onFormSubmission,
      onFormStepChange,
      onFirstClick,
      onTipsUpdate
    }
  }
}
