import { datadogLogs } from '@datadog/browser-logs'
import { Plugin } from '@nuxt/types'

const datadog: Plugin = ({ app }) => {
  const { $config } = app

  datadogLogs.init({
    clientToken: $config.DD_RUM_CLIENT_TOKEN,
    env: $config.DD_ENV,
    site: $config.DD_SITE,
    service: $config.DD_SERVICE,
    sessionSampleRate: parseInt($config.DD_RUM_SESSION_SAMPLE_RATE, 10)
  })
}

export default datadog
