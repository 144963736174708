import { functions } from '@ha/helpers'
import { AxiosError, AxiosResponse } from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import type {
  OrderDetailResponseGet,
  PaymentResponseGet,
  PaymentSummaryResponseApi
} from '~/components/payments/payment.interface'
import type { CollectionOf, FormsStoreRootState } from '~/types/common.interface'

export const state = (): {
  payment: PaymentResponseGet
  paymentId: string | null
  order: OrderDetailResponseGet
  useNewPaymentMethod: boolean
  summary: CollectionOf<PaymentSummaryResponseApi>
} => ({
  payment: {},
  paymentId: null,
  order: {},
  useNewPaymentMethod: true,
  summary: {}
})

export type PaymentState = ReturnType<typeof state>

export const getters: GetterTree<PaymentState, FormsStoreRootState> = {
  getPaymentId: state => state.paymentId,
  getPaymentDetails: state => state.payment,
  getOrderDetails: state => state.order,
  getNewPaymentMethod: state => state.useNewPaymentMethod,
  getPaymentGtmData: state => {
    const { payment } = state
    payment.amount = payment.amount || 0
    payment.amountTip = payment.amountTip || 0
    const amount = parseFloat(functions.convertToEuros(payment.amount))
    const totalAmount = functions.convertToEuros(payment.amount + payment.amountTip)
    const amountTip = functions.convertToEuros(payment.amountTip)
    const organizationName = state.order.organizationSlug?.replace(/\s/g, '-')

    return {
      event: 'purchase',
      transactionId: payment.id,
      transactionAffiliation: 'HelloAsso',
      transactionTotal: totalAmount,
      transactionTax: amountTip,
      transactionProducts: {
        name: `${organizationName}_${state.order.formSlug}`,
        price: amount,
        quantity: 1,
        sku: state.order.formType
      }
    }
  }
}

export const mutations: MutationTree<PaymentState> = {
  SET_PAYMENT_ID(state, id) {
    state.paymentId = id
  },
  SET_PAYMENT_DETAILS(state, payment) {
    state.payment = payment
  },
  SET_ORDER_DETAILS(state, order) {
    state.order = order
  },
  SAVE_NEW_PAYMENT_METHOD(state, paymentMethod) {
    state.useNewPaymentMethod = paymentMethod
  },
  SET_SUMMARY(state, { summary, id }) {
    state.summary[id] = summary
  }
}

export const actions: ActionTree<PaymentState, FormsStoreRootState> = {
  /**
   * fetch payment details
   * @param paymentId is returned by the oneTimeToken
   */
  getPaymentDetails({ commit, state }, id: string | null = null) {
    const paymentId = id || state.paymentId
    // @ts-expect-error import problem
    return this.$apiClient
      .get(`/users/me/payments/${paymentId}`)
      .then((response: AxiosResponse<PaymentResponseGet>) => {
        commit('SET_PAYMENT_DETAILS', response.data)
        return response.data
      })
      .catch((err: AxiosError | any) => {
        throw err
      })
  },
  /**
   * fetch order details
   */
  getOrderDetails(
    { commit },
    {
      orderId,
      fetchForm = false,
      fetchOrganization = false
    }: { orderId: number; fetchForm?: boolean; fetchOrganization?: boolean }
  ) {
    const url = `/users/me/orders/${orderId}?withForm=${fetchForm}&withOrganization=${fetchOrganization}`

    // @ts-expect-error import problem
    return this.$apiClient.get(url).then((response: AxiosResponse<OrderDetailResponseGet>) => {
      commit('SET_ORDER_DETAILS', response.data)

      if (fetchForm && response.data.form) {
        const { form } = response.data
        const { organizationSlug: organization, formSlug: slug, formType: type } = form

        commit('forms/FETCH_FORM', [form, { organization, slug, type }], { root: true })
      }

      if (fetchOrganization && response.data.organization) {
        commit('organizations/FETCH_ORGANIZATION', response.data.organization, { root: true })
      }

      return response.data
    })
  },
  /**
   * init a new payment try which redirects to LemonWay
   * @param paymentId is returned by the oneTimeToken
   */
  initNewPayment(
    _,
    { paymentId, useNewPaymentMethod }: { paymentId: number; useNewPaymentMethod: string }
  ): Promise<AxiosResponse<string>> {
    // @ts-expect-error import problem
    return this.$apiClient.post(
      `/payments/${paymentId}/reinitialize?useNewPaymentMethod=${useNewPaymentMethod}&backUrl=${location.href}`
    )
  },
  /**
   * Manage payment finalization
   * @param id is returned by LemonWay
   */
  fetchPaymentConfirmation(_, id) {
    // @ts-expect-error import problem
    return this.$apiClient.post(`/payments/unpaid/finalize?paymentRequestDataId=${id}`)
  },
  /**
   * Manage payment failure
   * @param id is returned by LemonWay
   */
  fetchPaymentFailure(_, id) {
    // @ts-expect-error import problem
    return this.$apiClient.post(`/payments/unpaid/error?paymentRequestDataId=${id}`)
  },
  /**
   * Manage payment cancellation
   * @param id is returned by LemonWay
   */
  fetchPaymentCancellation(_, id) {
    // @ts-expect-error import problem
    return this.$apiClient.post(`/payments/unpaid/abandon?paymentRequestDataId=${id}`)
  },

  getSummary({ commit }, id) {
    // @ts-expect-error import problem
    return this.$apiClient
      .get(`/payments/summary?paymentRequestId=${id}`)
      .then((response: AxiosResponse<PaymentSummaryResponseApi>) => {
        commit('SET_SUMMARY', { summary: response.data, id })
        return response.data
      })
  },
  /**
   * Get the next action of the payment process for a payment
   * @param paymentRequestId the payment request id
   * @returns
   */
  getNextAction(_, paymentRequestId) {
    const url = `/payments/card-embed/get-next-action?paymentRequestId=${paymentRequestId}`
    // @ts-expect-error import problem
    return this.$apiClient.get(url)
  },
  /**
   * Get the redirectionUrl of the payment process for a payment
   * @param paymentId the payment id
   * @returns
   */
  getPollRedirectUrl(_, paymentId) {
    const url = `/payments/card-embed/poll-redirect-url?paymentId=${paymentId}`
    // @ts-expect-error import problem
    return this.$apiClient.get(url)
  },
  /**
   * Initialize the payment process
   * @param paymentRequestId
   * @param paymentMethodId the payment method id depending on the provider
   * @param request (optional) the request to put in the body
   * @returns
   */
  initializePaymentStep(_, { paymentRequestId, paymentMethodId, request }) {
    let url = `/payments/card-embed/initialize?paymentRequestId=${paymentRequestId}`
    if (paymentMethodId) {
      url = `/payments/card-embed/initialize?paymentRequestId=${paymentRequestId}&paymentMethodId=${paymentMethodId}`
    }
    // Set two minutes of timeout to initialize a payment
    const timeout = 120000

    /* defaultRequest is used to ensure a content-type of application/json needed by the API */
    const defaultRequest = {}
    // @ts-expect-error import problem
    return this.$apiClient.post(url, request || defaultRequest, { timeout })
  },
  /**
   * Initialize the hapay payment process
   * @param paymentRequestId
   * @returns
   */
  initializeHaPayment(_, { paymentRequestId }) {
    let url = `/payments/card-embed/ha-initialize?paymentRequestId=${paymentRequestId}`

    // Set two minutes of timeout to initialize a payment
    const timeout = 120000

    /* defaultRequest is used to ensure a content-type of application/json needed by the API */
    const defaultRequest = {}
    // @ts-expect-error import problem
    return this.$apiClient.post(url, defaultRequest, { timeout })
  },
  /**
   * Reinitialize the payment process
   * @param unpaidPaymentId The unpaid payment Id
   * @returns
   */
  reinitializePaymentStep(_, { unpaidPaymentId, paymentMethodId, request = {} }) {
    let url = `/payments/card-embed/${unpaidPaymentId}/reinitialize`
    url += paymentMethodId !== undefined ? `?paymentMethodId=${paymentMethodId}` : ''
    // @ts-expect-error import problem
    return this.$apiClient.post(url, request)
  },
  /**
   * Execute the payment
   * @param paymentId the payment id of payment to execute
   * @param paymentRequestId
   * @param tokenizationSessionId the payment method id depending on the provider
   * @param requestBrowserData the request to put in the body
   * @returns
   */
  executePaymentStep(
    _,
    { paymentId, paymentRequestId, tokenizationSessionId, requestBrowserData }
  ) {
    let url = `/payments/card-embed/execute-payment?paymentId=${paymentId}&tokenizationSessionId=${tokenizationSessionId}`

    if (paymentRequestId) {
      url += `&paymentRequestId=${paymentRequestId}`
    }

    /* defaultRequest is used to ensure a content-type of application/json needed by the API */
    const request = requestBrowserData || {}

    // @ts-expect-error import problem
    return this.$apiClient.post(url, request, { timeout: 120_000 })
  },
  /**
   * Finalize the payment process
   * @param paymentId the payment Id received after initialization
   */
  finalizePaymentStep(_, paymentId) {
    // @ts-expect-error import problem
    return this.$apiClient.post(`/payments/finalize?paymentId=${paymentId}`)
  },
  /**
   * Confirm the payment when there is an authentication
   * @param apymentId the payment Id received after initialization
   */
  confirmPaymentStep(_, paymentId) {
    // @ts-expect-error import problem
    return this.$apiClient.post(`/payments/card-embed/confirm?paymentId=${paymentId}`)
  }
}
