import type { Middleware } from '@nuxt/types'
import { functions } from '@ha/helpers'

const urlNormalization: Middleware = ({ route, redirect }) => {
  // Normalize (and sanitize) URL with global common rules.
  const path: string = functions.getNormalizedUrl(route.path)

  if (path !== route.path) {
    redirect(301, { path })
  }
}

export default urlNormalization
