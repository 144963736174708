const middleware = {}

middleware['redirect-incomplete-url'] = require('../src/middleware/redirect-incomplete-url.ts')
middleware['redirect-incomplete-url'] = middleware['redirect-incomplete-url'].default || middleware['redirect-incomplete-url']

middleware['routeCaseFormat'] = require('../src/middleware/routeCaseFormat.ts')
middleware['routeCaseFormat'] = middleware['routeCaseFormat'].default || middleware['routeCaseFormat']

middleware['secure-iframe'] = require('../src/middleware/secure-iframe.ts')
middleware['secure-iframe'] = middleware['secure-iframe'].default || middleware['secure-iframe']

middleware['urlNormalization'] = require('../src/middleware/urlNormalization.ts')
middleware['urlNormalization'] = middleware['urlNormalization'].default || middleware['urlNormalization']

export default middleware
