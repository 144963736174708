import { useContext } from '@nuxtjs/composition-api'
import { useSsrState } from '@ha/components'

const haToaster = useSsrState('haToaster', () => null)

export default () => {
  const { i18n } = useContext()

  const onError = (error: any, params?: any) => {
    const status = error?.response?.status || error?.statusCode || 500
    const toast = error?.toast || {
      type: 'danger',
      title: params?.title ?? i18n.t(`error.code.${status}.title`),
      body: params?.body ?? i18n.t(`error.code.${status}.message`),
      timeout: 5000
    }
    console.error(error)
    haToaster.value.push(toast)
  }

  return { haToaster, onError }
}
