<template>
  <a href="/" class="HeaderLogo" data-ux="Forms_Logo_GoToHome">
    <img
      src="~assets/images/logos/logo-helloasso-midnight.svg"
      alt="Helloasso"
      loading="lazy"
    />
  </a>
</template>

<script>
export default {
  name: 'HeaderLogo'
}
</script>

<style lang="scss">
.HeaderLogo {
  display: flex;
  align-items: center;
  width: $ha-unit * 20; // 160px
  height: auto;
}
</style>
