import campaigns from './campaigns'
/*
This plugin downloads and executes AbTasty on app creation client side only
It then provides methods to handle AbTasty
*/

// eslint-disable-next-line no-use-before-define
initAbTasty()

const isAbTastyReady = () =>
  typeof window.ABTasty !== 'undefined' &&
  typeof window.ABTasty.eventState !== 'undefined' &&
  !!window.ABTasty.eventState['executedCampaign'] &&
  window.ABTasty.eventState['executedCampaign'].status === 'complete'

// We link chosen campaign with its callbacks.
// The callbacks contain the expected behavior for campaign and variation.
// We have to keep track of callbacks to avoid to call them twice
const campaignCallbacks = {}

const campaignExecuted = detail => {
  const { campaignId } = detail

  /* Uncomment for debug purpose
  const campaignVariation = detail.variationId;
  console.log(`Campaign ${campaignId} has been executed with variation ${campaignVariation}`); */

  if (campaignCallbacks[campaignId]) {
    campaignCallbacks[campaignId].forEach(callback => {
      callback(detail)
    })
    delete campaignCallbacks[campaignId]
  }
}

const addCallback = (chosenCampaignId, callback) => {
  if (!campaignCallbacks[chosenCampaignId]) {
    campaignCallbacks[chosenCampaignId] = []
  }

  campaignCallbacks[chosenCampaignId].push(callback)

  if (isAbTastyReady()) {
    window.ABTasty.eventState['executedCampaign'].detail.forEach(campaignDetail => {
      if (campaignDetail.campaignId === chosenCampaignId) {
        campaignExecuted(campaignDetail)
      }
    })
  }
}

// Code from AbTasty doc
function loadSdk () {
  window["abtiming"] = 1 * new Date()
  const scriptElement = document.createElement("script")
  const firstScript = document.getElementsByTagName("script")[0]
  scriptElement.async = 1
  scriptElement.src = "//try.abtasty.com/6f1e1e9c6ab8a982efc6bd92e90da58d.js"
  firstScript.parentNode.insertBefore(scriptElement, firstScript)
}

function initAbTasty () {
  window.addEventListener('abtasty_executedCampaign', e => {
    campaignExecuted(e.detail)
  })

  loadSdk()
}

const sendCustomTrackingEvent = name => {
  window.abtasty.send("event", {
    ec: "Custom Tracking",
    ea: name,
  })
}

const sendTransactionEvent = data => {
  // According to AbTasty tt, ts and tr must be Number, but we have previously encounter some bug
  // and it seems it's better to have a string in case we have a 0

  // It's AbTasty's keys
  window.abtasty.send("transaction", {
    tid: data.id,         // MANDATORY ID du paiement
    ta: data.name,       // MANDATORY Transaction Affiliation - Name of the transaction goal
    tr: data.tr,            // MANDATORY Transaction Revenue -> final tip
    ts: data?.ts,              // Transaction Shipping = transactionTotal = total cart amount
    icn: 1,               // MANDATORY Number of items, 1 is default value
    sm: data?.sm, // shipping method -> userEmail
    tt: data?.tt,    //  transaction tax-> tip suggested
    pm: data?.pm, // category orga
    tcc: data?.tcc, // Coupon code -> formType
    iv: data?.iv // provider but doesn't work
  })
}

export default (_, inject) => {
  inject('abTasty', {
    campaigns,
    addCallback,
    sendCustomTrackingEvent,
    sendTransactionEvent
  })
}
