import { ExtraOption } from './extraOptions.interface'

export const getRemainingStock = (FormTierExtraOption: ExtraOption, selectedExtraOptionIds: number[]) => {
  /* Need to get remaining number from tier's data to be up-to-date */
  const remainingNumber = FormTierExtraOption?.remainingNumber

  if (!Number.isInteger(remainingNumber)) {
    return null
  }

  const sumSelectedOptions = selectedExtraOptionIds.filter(
    id => id === FormTierExtraOption?.id
  ).length

  const delta = (remainingNumber || 0) - sumSelectedOptions

  return Math.max(delta, 0)
}
