import { Middleware } from '@nuxt/types'
// Refacto - TODO

// Reformats route slugs
const routeFormat: Middleware = ({ route, redirect }) => {
  const lowerCaseOnlyParams = ['organizationSlug', 'formType', 'campaignSlug']
  const paramsEntries = Object.entries(route.params)
  const filteredParamsEntries = paramsEntries.filter(([key]) => lowerCaseOnlyParams.includes(key))
  const hasInvalidParams = filteredParamsEntries.some(([, value]) => /[A-Z+\s]/g.test(value))

  // the router goes through the middleware first, but it could be that the url is invalid
  // i.e. "/associations/{invalid_organization_slug}/{form_type_slug}"
  // Checking for undefined params avoids the redirection in that case
  const hasUndefinedParams = filteredParamsEntries.some(([, value]) => value === undefined)

  // formats the slug to lowercase and replaces '+' and space characters with '-'
  if (hasInvalidParams && !hasUndefinedParams) {
    const validCaseParams = filteredParamsEntries.reduce((acc, [key, value]) => {
    return { ...acc, [key]: value
      .toLowerCase()
      .replace(/[+\s]/g, '-')}
  }, {})

    redirect({
      name: route.name as string,
      path: route.path,
      hash: route.hash,
      params: validCaseParams,
      query: route.query
    })
  }
}

export default routeFormat
