import { computed } from 'vue'
import { useRoute } from '@nuxtjs/composition-api'
import { enum as enums } from '@ha/helpers'

export default () => {
  const route = useRoute()
  const organizationSlug = computed(() =>
    route.value?.params?.organizationSlug?.replace(/\s/g, '-')
  )
  const formSlug = computed(() => route.value?.params?.campaignSlug?.replace(/\s/g, '-'))
  const formType = computed(() => enums.UrlToFormType[route.value?.params?.formType])
  const formTypeUrl = computed(() => route.value?.params?.formType)
  const storeRouteParams = computed(() => ({
    organization: organizationSlug.value,
    slug: formSlug?.value,
    type: formType?.value?.toUpperCase()
  }))
  const checkoutCartIdParams = computed(() => route.value?.params?.cartId)

  const step = computed(() => {
    return parseInt(route.value.params.steps, 10) || 1
  })
  const isFirtStep = computed(() => {
    return step.value === 1
  })

  return {
    organizationSlug,
    formSlug,
    formType,
    formTypeUrl,
    storeRouteParams,
    checkoutCartIdParams,
    step,
    isFirtStep
  }
}
