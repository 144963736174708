import { Tracking } from '@ha/helpers'
import getTrackingRouteData from '@/helpers/getTrackingRouteData'

export default ({ app }) => {
  const tracking = new Tracking({
    segmentKey: app.$config.SEGMENT_KEY,
    enabled: app.$config.SEGMENT_ENABLED
  })
  const excludedPages = ['/confirmation/', '/redirection', '/embarque']
  app.router.afterEach(route => {
    if (!excludedPages.some(page => route.path.includes(page))) {
      tracking.page(getTrackingRouteData(route))
    }
  })
}
