import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import HttpService, { HttpServiceConfig } from './httpService'
import HttpAuthService from './httpAuthService'
import { NuxtError } from '@nuxt/types'
import { GetNuxtErrorFromAxiosError } from '../helpers/error'

class HttpApiService extends HttpService {
  /**
   * `clientRetry` is an Axios Instance without interceptor to prevent infinite loop on retry.
   * It is used to retry a call after request error.
   */
  clientRetry: AxiosInstance

  constructor(config: HttpServiceConfig, readonly httpAuthService?: HttpAuthService) {
    super(config)
    this.clientRetry = this.create()
  }

  override handlerRequestConfig = async (config: AxiosRequestConfig) => {
    this.requestLoggerInfo(config)
    try {
      if (!this.httpAuthService) {
        throw new Error('httpAuthService is required')
      }
      config = await this.httpAuthService.setBearerFromCookieOrRefresh(config)
      return config
    } catch (error: any) {
      throw error
    }
  }

  override handlerRequestError = async (axiosError: AxiosError) => {
    this.requestLoggerError(axiosError)
    const nuxtError = GetNuxtErrorFromAxiosError(axiosError)

    try {
      switch (true) {
        case nuxtError.statusCode === 421:
        case nuxtError.message === 'Network Error':
          return await this.useProxyOnError421(axiosError)

        default:
          return Promise.reject(nuxtError)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  override handlerResponseError = async (axiosError: AxiosError): Promise<any> => {
    this.responseLoggerError(axiosError)
    const nuxtError = GetNuxtErrorFromAxiosError(axiosError)

    try {
      switch (true) {
        case nuxtError.statusCode === 421:
        case nuxtError.message === 'Network Error':
          const retryResponse = await this.useProxyOnError421(axiosError)
          return retryResponse

        case nuxtError.statusCode === 401:
          const forceAuthResponse = await this.forceAuthOnError401(axiosError)
          return forceAuthResponse

        default:
          // Returns a plain object to prevent errors that happens because of AxiosError functions
          return Promise.reject(nuxtError)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * ### Use Proxy On Error 421
   * Retry an API call via service middleware. This error happen only on Safari browser
   * @param error
   * @returns
   */
  useProxyOnError421 = async (
    error: AxiosError<{ response: { message: string } }>
  ): Promise<any | AxiosResponse> => {
    const log = `${this.name} -- ↘️ request err 421 : ', ${error.code || error.response?.status}, ${
      error.message
    }`
    this.loggerConsole?.warn(`\n${log}\n`)
    this.logger?.warn(log)
    try {
      if (!this.httpProxyService) {
        throw new Error('httpProxyService is required')
      }

      const retryResponse = await this.httpProxyService.client({
        url: error.config.url,
        method: error.config.method,
        headers: error.config.headers,
        params: error.config.params,
        data: error.config.data
      })

      return retryResponse
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * ### Force Auth on Error 401
   * Error 401 happen when token is expired
   * @param error
   * @returns
   */
  forceAuthOnError401 = async (error: AxiosError): Promise<any> => {
    const log = `${this.name} -- ↘️ response err 401 - forceAuthOnError401 : ${error.response?.status} ${error.message}`
    this.loggerConsole?.warn(`\n${log}\n`)
    this.logger?.warn(log)
    try {
      if (this.httpAuthService) {
        // fetch new token
        const config = await this.httpAuthService.setBearerFromCookieOrRefresh({
          url: error.config.url,
          method: error.config.method,
          headers: error.config.headers,
          params: error.config.params,
          data: error.config.data
        })

        // retry request
        const forceAuthResponse = await this.clientRetry({
          url: config.url,
          method: config.method,
          headers: config.headers,
          params: config.params,
          data: config.data
        })
        return forceAuthResponse
      } else {
        return new Error('httpAuthService is required')
      }
    } catch (error: any) {
      const erroLog = `${this.name} -- forceAuthOnError401 - ERROR : ${error.response?.status} ${error.message}`
      this.loggerConsole?.error(`\n${erroLog}\n`)
      this.logger?.warn(erroLog)
      return Promise.reject(error)
    }
  }
}

export default HttpApiService
