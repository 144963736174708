export default {
  description: {
    customAmount: 'Prix Libre | À partir de {min} | À partir de {min}',
    defaultTitle: 'Contribution'
  },
  price: {
    perMonth: 'Par mois',
    perTime: 'Par échéance',
    scheduledTotal: '(Soit {total} au total)'
  },
  selector: {
    soldOut: 'Épuisé',
    soldOutLimited: 'Limité',
    labelInput: 'Quantité',
    freeInput: 'Tarif libre à remplir',
    freeDonationInput: 'Don libre à remplir'
  },
  Event: {
    maxByTier:
      'Il ne reste plus de place disponible | {max} place restante | {max} places restantes',
    maxByForm:
      "L'évènement est limité à {max} places | {max} place restante | {max} places restantes",
    maxByUser: 'Limité à {max} par personne',
    maxByCart: 'Le nombre maximum de {max} places payables en une fois a été atteint.',
    maxByExtraOption:
      'Il ne reste plus que {max} places car les options obligatoires associées sont épuisées.',
    vatRate: 'Inscription soumise à TVA ({vat} %)'
  },
  Membership: {
    maxByTier:
      'Il ne reste plus de place disponible | {max} place restante | {max} places restantes',
    maxByForm:
      "L'adhésion est limitée à {max} places | {max} place restante | {max} places restantes",
    maxByUser: 'Limité à {max} par personne',
    maxByCart: 'Le nombre maximum de {max} places payables en une fois a été atteint.',
    maxByExtraOption:
      'Il ne reste plus que {max} places car les options obligatoires associées sont épuisées.',
    vatRate: 'Inscription soumise à TVA ({vat} %)'
  },
  Shop: {
    maxByTier:
      'Il ne reste plus de produit disponible | {max} produit restant | {max} produits restants',
    maxByForm:
      'La boutique est limitée à {max} produits | {max} produit restant | {max} produits restants',
    maxByUser: 'Limité à {max} par personne',
    maxByCart: 'Le nombre maximum de {max} produits payables en une fois a été atteint.',
    maxByExtraOption:
      'Il ne reste plus que {max} produits car les options obligatoires associées sont épuisées.',
    vatRate: 'Achat soumis à TVA ({vat} %)'
  },
  CrowdFunding: {
    maxByTier:
      'Il ne reste plus de contrepartie disponible | {max} contrepartie restante | {max} contreparties restantes',
    maxByForm:
      'La collecte est limitée à {max} contreparties | {max} contrepartie restante | {max} contreparties restantes',
    maxByUser: 'Limité à {max} par personne',
    maxByCart: 'Le nombre maximum de {max} contreparties payables en une fois a été atteint.',
    maxByExtraOption:
      'Il ne reste plus que {max} contreparties car les options obligatoires associées sont épuisées.',
    vatRate: 'Contrepartie soumise à TVA ({vat} %)'
  },
  Donation: {
    maxByTier: 'Il ne reste plus de don disponible | {max} don restant | {max} dons restants',
    maxByForm:
      'La campagne de don est limitée à {max} dons | {max} don restant | {max} dons restants',
    maxByUser: 'Limité à {max} par personne',
    maxByCart: 'Le nombre maximum de {max} dons payables en une fois a été atteint.',
    maxByExtraOption:
      'Il ne reste plus que {max} dons car les options obligatoires associées sont épuisées.',
    vatRate: 'Don soumis à TVA ({vat} %)'
  },
  PaymentForm: {
    maxByTier:
      'Il ne reste plus de paiement disponible | {max} paiement restant | {max} paiements restants',
    maxByForm:
      'La vente est limitée à {max} paiements | {max} paiement restant | {max} paiements restants',
    maxByUser: 'Limité à {max} par personne',
    maxByCart: 'Le nombre maximum de {max} paiements payables en une fois a été atteint.',
    maxByExtraOption:
      'Il ne reste plus que {max} paiements car les options obligatoires associées sont épuisées.',
    vatRate: 'Achat soumis à TVA ({vat} %)'
  },
  Checkout: {
    maxByTier:
      'Il ne reste plus de paiement disponible | {max} paiement restant | {max} paiements restants',
    maxByForm:
      'Le paiement est limitée à {max} paiements | {max} paiement restant | {max} paiements restants',
    maxByUser: 'Limité à {max} par personne',
    maxByCart: 'Le nombre maximum de {max} paiements payables en une fois a été atteint.',
    maxByExtraOption:
      'Il ne reste plus que {max} paiements car les options obligatoires associées sont épuisées.',
    vatRate: 'Achat soumis à TVA ({vat} %)'
  }
}
