import { AxiosResponse, AxiosInstance } from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import type { FormsStoreRootState } from '~/types/common.interface'
import type {
  Contributor,
  ContributorsGetResponse,
  ContributorsGetterParams
} from '../components/contributors/contributors.interface'

export const state = () => ({
  contributors: [] as Contributor[]
})

export type ContributorsState = ReturnType<typeof state>

export const mutations: MutationTree<ContributorsState> = {
  SET_CONTRIBUTORS(state, contributors) {
    state.contributors = [...state.contributors, ...contributors]
  }
}
export const getters: GetterTree<ContributorsState, FormsStoreRootState> = {
  getContributors: state => () => {
    return state.contributors
  }
}
export const actions: ActionTree<ContributorsState, FormsStoreRootState> = {
  fetchContributors(
    { commit },
    { organization, slug, type, continuationToken }: ContributorsGetterParams
  ) {
    let url: string = `/organizations/${organization}/forms/${type}/${slug}/contributors?pageSize=8`

    if (continuationToken) {
      url += `&continuationToken=${continuationToken}`
    }
    // @ts-expect-error import issue
    return (this.$apiClient as AxiosInstance)
      .get(url)
      .then((response: AxiosResponse<ContributorsGetResponse>) => {
        commit('SET_CONTRIBUTORS', [...response.data.data])
        return response.data
      })
      .catch(e => {
        throw e
      })
  }
}
